import { mapState } from "vuex";

import {
  MFA_GET,
  MFA_GET_RESET,
  MFA_ENABLE,
  MFA_DISABLE,
  MFA_GOOGLE_INFO_GET,
} from "@/store/mfa/actions";

export default {
  props: ['itemId'],
  data() {
    return {
      mfa: {
        code: null,
        method: null,
        google2fa_secret: null,
      }
    }
  },
  async beforeMount() {
    const isMe = this.userData.id === this.itemId;
    const hasRole = this.$role('INTERNAL_SUPPORT') || this.$role('ADMIN');
    if (isMe || hasRole) {
      await this.$store.dispatch(MFA_GET_RESET);
      await this.getMfaConfig();
    }
  },
  methods: {
    async getMfaConfig() {
      await this.$store.dispatch(MFA_GET, await this.getParams());
    },
    async disableMFA() {
      await this.$store.dispatch(MFA_DISABLE, await this.getParams());
      this.showNotification(this.$lang('users.mfa.disabled'), 'success');
      this.$emit("success");
    },
    async enableMFA(params) {
      await this.$store.dispatch(MFA_ENABLE, await this.getParams(params));
      this.showNotification(this.$lang('users.mfa.succeeded'), 'success');
      this.$emit("success");
    },
    async getGoogleInfo() {
      await this.$store.dispatch(MFA_GOOGLE_INFO_GET, await this.getParams());
      this.mfa.google2fa_secret = this.googleInfo.secret;
    },
    async getParams(params) {
      params = params || {};
      if (this.itemId) {
        params.user_id = this.itemId;
      }
      return params;
    },
    async preEnableMFA() {
      if (!this.mfa.mfa_method) {
        this.showNotification(this.$lang('users.mfa.choose_error'), 'error');
        return;
      }
      if (this.mfa.mfa_method === this.$const('ConstMfaMethods', 'GOOGLE')) {
        if (this.mfa.google2fa_secret) {
          await this.enableMFA({
            code: this.mfa.code,
            mfa_method: this.mfa.mfa_method,
            google2fa_secret: this.mfa.google2fa_secret,
          });
        } else {
          await this.getGoogleInfo();
        }
      }
      if (this.mfa.mfa_method === this.$const('ConstMfaMethods', 'EMAIL')) {
        await this.enableMFA({
          mfa_method: this.mfa.mfa_method,
        });
      }
    },
  },
  computed: {
    ...mapState({
      mfaConfig: state => state.mfa.getRequest.data,
      mfaConfigRequest: state => state.mfa.getRequest,
      enableRequest: state => state.mfa.enableRequest,
      disableRequest: state => state.mfa.disableRequest,
      googleInfo: state => state.mfa.googleInfoRequest.data,
      googleInfoRequest: state => state.mfa.googleInfoRequest,
      enableRequestValidation: state => state.mfa.enableRequest.errors,
    }),
  },
};
