import Echo from "laravel-echo";
import Pusher from "pusher-js";
import apiConfig from '@/config/api';
import broadcastConfig from '@/config/broadcast';
import SessionService from '@/libraries/sessionjs/src';
import broadcast from "../config/broadcast";

export default class BroadcastService {
  /**
   *
   */
  constructor() {
    if (!broadcastConfig.enabled) {
      return;
    }

    const token = SessionService.get('token');

    this.broadcaster = new Echo({
      broadcaster: 'pusher',
      key: broadcast.soketi.key,
      wsHost: broadcast.soketi.wsHost,
      wsPort: broadcast.soketi.wsPort,
      wssPort: broadcast.soketi.wssPort,
      forceTLS: true,
      disableStats: true,
      encrypted: true,
      enabledTransports: ['ws', 'wss'],
      auth: {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
      authEndpoint: `${apiConfig.uri}/channels/auth`
    });
  }

  /**
   * Leave channel
   *
   * @param channel
   */
  static leave(channel) {
    if (!broadcastConfig.enabled) {
      return;
    }

    const instance = new BroadcastService();
    instance.broadcaster.leave(channel);
  }

  /**
   * Listen to channel
   *
   * @param channel
   * @param event
   * @param callback
   */
  static listen(channel, event, callback) {
    if (!broadcastConfig.enabled) {
      return;
    }

    const instance = new BroadcastService();
    instance.broadcaster.channel(channel)
      .listen(event, (e) => {
        callback(e);
      });
  }

  /**
   * Listen to private channel
   *
   * @param channel
   * @param event
   * @param callback
   */
  static listenPrivate(channel, event, callback) {
    if (!broadcastConfig.enabled) {
      return;
    }

    const instance = new BroadcastService();
    instance.broadcaster.private(channel)
      .listen(event, (e) => {
        callback(e);
      });
  }

  /**
   * Notification channel
   *
   * @param channel
   * @param callback
   */
  static notification(channel, callback) {
    if (!broadcastConfig.enabled) {
      return;
    }

    const instance = new BroadcastService();
    instance.broadcaster.channel(channel)
      .notification(notification => {
        callback(notification);
      });
  }

  /**
   * Notification private channel
   *
   * @param channel
   * @param callback
   */
  static notificationPrivate(channel, callback) {
    if (!broadcastConfig.enabled) {
      return;
    }

    const instance = new BroadcastService();
    instance.broadcaster.private(channel)
      .notification(notification => {
        callback(notification);
      });
  }
};
