<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" xs="12" md="12">
            <v-text-field
              v-model="form.name"
              :label="$lang('users.fields.name')"
              :hint="$lang('users.hints.name')"
              :error="!!validation.name"
              :error-messages="!!validation.name ? validation.name : []"
            ></v-text-field>
            <v-text-field
              v-model="form.email"
              :label="$lang('users.fields.email')"
              :hint="$lang('users.hints.email')"
              :error="!!validation.email"
              :error-messages="!!validation.email ? validation.email : []"
            ></v-text-field>
            <v-text-field
              v-model="form.password"
              :label="$lang('users.fields.password')"
              :hint="$lang('users.hints.password')"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              :error="!!validation.password"
              :error-messages="!!validation.password ? validation.password : []"
            ></v-text-field>
            <v-text-field
              v-model="form.password_confirm"
              :label="$lang('users.fields.password_confirm')"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              :error="!!validation.password_confirm"
              :error-messages="!!validation.password_confirm ? validation.password_confirm : []"
            ></v-text-field>
            <v-locale
              v-model="form.locale"
              :label="$lang('users.fields.locale')"
              :hint="$lang('users.hints.locale')"
              persistent-hint
              :error="!!validation.locale"
              :error-messages="!!validation.locale ? validation.locale : []"
            />
            <v-select
              v-model="form.date_format"
              :items="$in('ConstDateFormat')"
              :label="$lang('users.fields.date_format')"
              :hint="$lang('users.hints.date_format')"
              persistent-hint
              :error="!!validation.date_format"
              :error-messages="!!validation.date_format ? validation.date_format : []"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="my-2">
          <v-col>
            <h3>
              {{ $lang('users.im.telegram.title') }}
            </h3>
            <div class="subtitle">{{ $lang('users.im.telegram.subtitle') }}</div>
            <v-row v-if="!form.telegram_id" class="mt-2">
              <v-col>
                <v-btn :href="`https://t.me/ciaobookingbot?start=a-${form.id}`" target="_blank" color="success" block>
                  {{$lang('users.im.actions.connect')}}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn v-if="!form.telegram_id" @click="getTelegramUserId()" color="primary" block>
                  {{$lang('users.im.actions.verify')}}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="form.telegram_id" class="mt-2">
              <v-col>
                <v-btn @click="form.telegram_id = null" color="error" block>
                  {{$lang('users.im.actions.detach')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="my-2">
          <v-col><v-btn color="primary" @click="openMfa()">{{ $lang("users.mfa.title") }}</v-btn></v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="submit()" :loading="loading" :disabled="loading">{{ $lang("main.crud.save") }}</v-btn>
      <v-btn color="error" @click="cancel()">{{ $lang("main.crud.cancel") }}</v-btn>
    </v-card-actions>
    <v-dialog v-model="isOpenMfa" transition="dialog-bottom-transition" width="30%">
      <mfa-form v-if="isOpenMfa" @cancel="closeMfa" @success="closeMfa" :item-id="itemId"></mfa-form>
    </v-dialog>
  </v-card>
</template>

<script>
  import { mapState } from "vuex";
  import form from "@/pages/users/mixins/form";
  import { GET_USERS_SINGLE, REPLACE_USERS, REPLACE_USERS_RESET } from "@/store/users/actions";
  import CreationForm from "@/pages/users/CreationForm.vue";
  export default {
    name: "EditingForm",
    components: { CreationForm },
    mixins: [form],
    props: ['itemId'],
    beforeMount() {
      this.getItem();
    },
    methods: {
      resetAction() {
        return REPLACE_USERS_RESET;
      },
      submitAction() {
        return REPLACE_USERS;
      },
      submitActionOptions() {
        return {
          id: this.itemId,
          params: this.form
        };
      },
      populate(item) {
        this.form = {
          ...this.form,
          ...item
        };
      },
      getItem() {
        this.$store.dispatch(GET_USERS_SINGLE, {
          id: this.itemId,
        });
      }
    },
    computed: {
      ...mapState({
        item: state => state.users.getSingleRequest.item,
        loading: state => state.users.replaceRequest.loading,
        validation: state => state.users.replaceRequest.validation,
      })
    },
  };
</script>
<style scoped>
</style>
