<template>
  <v-card>
    <v-form>
      <form-header :fullscreen="false" :title="$lang('users.mfa.title')"></form-header>
      <v-card-text>
        <v-container>
          <v-skeleton-loader
            v-show="mfaConfigRequest.loading"
            :tile="false"
            :boilerplate="false"
            type="table-row-divider@2"
            class="mx-auto"
          ></v-skeleton-loader>
          <v-row v-show="!mfaConfigRequest.loading">
            <v-col cols="12" xs="12" md="12">
              <template v-if="mfaConfig.mfa_method">
                <div class="subtitle">{{ $lang('users.mfa.set', { method: $constText('ConstMfaMethods', mfaConfig.mfa_method) }) }}</div>
              </template>
              <template v-else>
                <template v-if="mfa.google2fa_secret">
                  <v-img :src="googleInfo.image" width="300px" />
                  <v-text-field
                    v-model="mfa.google2fa_secret"
                    :label="$lang('users.mfa.google2fa_secret')"
                    readonly
                  />
                  <span>{{ $lang('users.mfa.code') }}</span>
                  <v-otp-input
                    v-model="mfa.code"
                    :error="!!enableRequestValidation.code"
                    :error-messages="!!enableRequestValidation.code ? enableRequestValidation.code : []"
                  />
                </template>
                <template v-else>
                  <v-radio-group dense v-model="mfa.mfa_method" :mandatory="false">
                    <v-radio
                      v-for="(method, i) in $in('ConstMfaMethods')"
                      :key="i"
                      :label="method.text"
                      :value="method.value"
                    />
                  </v-radio-group>
                </template>
              </template>

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions v-show="!mfaConfigRequest.loading">
        <v-spacer></v-spacer>
        <v-btn v-if="mfaConfig.mfa_method" @click="disableMFA" color="error" block :loading="disableRequest.loading">
          {{ $lang('users.mfa.disable') }}
        </v-btn>
        <v-btn v-else @click="preEnableMFA" color="success" block :loading="googleInfoRequest.loading || enableRequest.loading">
          {{ $lang('users.mfa.enable') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
  import formable from "@/mixins/formable";
  import mfa from "@/pages/users/mixins/mfa";
  export default {
    name: "MfaForm",
    mixins: [mfa, formable],
  };
</script>
<style scoped></style>
