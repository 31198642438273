import mixpanel from "mixpanel-browser";

class MonitorService {
  constructor() {
    const token = process.env.VUE_APP_MIXPANEL_TOKEN;

    if (!token) {
      throw new Error(
        "Mixpanel token is missing. Please define VUE_APP_MIXPANEL_TOKEN in your .env file."
      );
    }

    const proxy = process.env.VUE_APP_MIXPANEL_PROXY;
    const debug = process.env.VUE_APP_MIXPANEL_DEBUG;

    const config = {
      debug: Boolean(debug),
      track_pageview: true,
      persistence: "localStorage",
      ignore_dnt: true,
      ...(proxy && { api_host: proxy }),
      record_sessions_percent: 100,
      record_mask_text_selector: null,
    };

    mixpanel.init(token, config);
    this.mixpanel = mixpanel;
  }

  /**
   * Identify a user in Mixpanel.
   * @param {string} userId - Unique identifier for the user.
   */
  identify(userId) {
    this.mixpanel.identify(userId);
  }

  /**
   * Track an event in Mixpanel.
   * @param {string} eventName - Name of the event to track.
   * @param {Object} [properties={}] - Optional properties to send with the event.
   */
  track(eventName, properties = {}) {
    this.mixpanel.track(eventName, properties);
  }

  /**
   * Set properties on a user profile.
   * @param {Object} properties - Properties to set on the user profile.
   */
  setProfileProperties(properties) {
    this.mixpanel.people.set(properties);
  }

  /**
   * Increment a numeric property on a user profile.
   * @param {string} property - Property name to increment.
   * @param {number} value - Value by which to increment.
   */
  incrementProfileProperty(property, value = 1) {
    this.mixpanel.people.increment(property, value);
  }

  /**
   * Alias the current user ID with a new one.
   * @param {string} alias - New alias for the current user ID.
   */
  alias(alias) {
    this.mixpanel.alias(alias);
  }

  /**
   * Reset the Mixpanel instance, e.g., when a user logs out.
   */
  reset() {
    this.mixpanel.reset();
  }

  /**
   * Opt a user out of tracking.
   */
  optOut() {
    this.mixpanel.opt_out_tracking();
  }

  /**
   * Opt a user back into tracking.
   */
  optIn() {
    this.mixpanel.opt_in_tracking();
  }
}

export default new MonitorService();
