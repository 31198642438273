<template>
  <v-card>
    <v-form>
      <form-header
        :form="form"
        tour="user-creation-form"
        :title="$lang('users.title_create')"
      >
        <template v-slot:left-buttons>
          <v-btn icon dark @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <template v-slot:toolbar-items>
          <v-btn text @click="submit()" :loading="loading" :disabled="loading">
            {{ $lang("main.crud.create") }}
          </v-btn>
        </template>
      </form-header>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="py-0" cols="12" xs="12" md="8">
              <v-alert v-if="validation.roles" type="error">
                {{ validation.roles[0] }}
              </v-alert>
              <v-text-field
                v-model="form.name"
                :label="$lang('users.fields.name')"
                :hint="$lang('users.hints.name')"
                :error="!!validation.name"
                :error-messages="!!validation.name ? validation.name : []"
              ></v-text-field>
              <v-text-field
                v-model="form.email"
                :label="$lang('users.fields.email')"
                :hint="$lang('users.hints.email')"
                :error="!!validation.email"
                :error-messages="!!validation.email ? validation.email : []"
              ></v-text-field>
              <v-text-field
                v-model="form.password"
                :label="$lang('users.fields.password')"
                :hint="$lang('users.hints.password')"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :error="!!validation.password"
                :error-messages="!!validation.password ? validation.password : []"
                autocomplete="off"
              />
              <v-text-field
                v-model="form.password_confirm"
                :label="$lang('users.fields.password_confirm')"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :error="!!validation.password_confirm"
                :error-messages="!!validation.password_confirm ? validation.password_confirm : []"
                autocomplete="off"
              />
              <v-locale
                v-model="form.locale"
                :label="$lang('users.fields.locale')"
                :hint="$lang('users.hints.locale')"
                :error="!!validation.locale"
                :error-messages="!!validation.locale ? validation.locale : []"
              />
              <v-select
                v-model="form.date_format"
                :items="$in('ConstDateFormat')"
                :label="$lang('users.fields.date_format')"
                :hint="$lang('users.hints.date_format')"
                persistent-hint
                :error="!!validation.date_format"
                :error-messages="!!validation.date_format ? validation.date_format : []"
              >
              </v-select>
              <v-select
                v-model="form.after_login_redirect"
                :items="allRoutes"
                :label="$lang('users.fields.after_login_redirect')"
                :hint="$lang('users.hints.after_login_redirect')"
                persistent-hint
                :error="!!validation.after_login_redirect"
                :error-messages="!!validation.after_login_redirect ? validation.after_login_redirect : []"
              >
              </v-select>
              <v-row>
                <v-col cols="6">
                  <v-boolean
                    v-model="form.is_active"
                    :label="$lang('users.fields.is_active')"
                    :hint="$lang('users.hints.is_active')"
                    persistent-hint
                    :error="!!validation.is_active"
                    :error-messages="!!validation.is_active ? validation.is_active : []"
                  />
                </v-col>
                <v-col cols="6" v-if="$can('INTERNAL_SUPPORT')" class="admin">
                  <v-boolean
                    v-model="form.is_chat_enabled"
                    :label="$lang('users.fields.is_chat_enabled')"
                  />
                  <v-boolean
                    v-model="form.is_ticket_enabled"
                    :label="$lang('users.fields.is_ticket_enabled')"
                  />
                  <v-text-field
                    v-model="form.telegram_id"
                    :label="$lang('users.fields.telegram_id')"
                    :error="!!validation.telegram_id"
                    :error-messages="!!validation.telegram_id ? validation.telegram_id : []"
                  />
                </v-col>
              </v-row>
              <h3>{{ $lang('users.panels.roles.title') }}</h3>
              <v-list>
                <v-list-group v-for="(roleType, rt) in computedRoles" two-line :key="rt">
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <v-icon>{{ roleIcon(rt) }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ $lang(`users.panels.roles.types.${rt}`) }}
                    </v-list-item-title>
                  </template>

                  <v-list>
                    <v-list-item-group v-model="form.roles" multiple>
                      <v-list-item
                        v-for="(role, r) in roleType" :key="r"
                        :value="role.name"
                        three-line
                      >
                        <template v-slot:default="{ active, }">
                          <v-list-item-action>
                            <v-checkbox
                              :input-value="active"
                              :color="roleColor(role)"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $constText('ConstRoles', role.name) }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{$lang(`users.roles_description.${role.name}`)}}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-list-group>
              </v-list>
            </v-col>
            <v-col cols="12" md="4">
              <v-card outlined color="blue-grey lighten-5">
                <v-card-title>
                  {{ $lang('users.fields.assigned_properties') }}
                </v-card-title>
                <v-card-text v-if="!companiesRequest.loading">
                  <v-container>
                    <v-alert v-if="validation.properties" type="error">
                      {{ validation.properties[0] }}
                    </v-alert>
                    <v-row>
                      <v-col cols="12" xs="12" md="12">
                        <v-text-field
                          v-model="search"
                          :label="$lang('main.settings.search_property')"
                          flat
                          clearable
                          prepend-inner-icon="mdi-magnify"
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="6" md="6" align="start" class="">
                        <v-btn @click="all" x-small>{{ $lang('main.settings.select_all') }}</v-btn>
                      </v-col>
                      <v-col cols="12" xs="6" md="6" align="end" class="">
                        <v-btn @click="clear" x-small>{{ $lang('main.settings.deselect_all') }}</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-treeview
                    v-model="form.properties"
                    :items="companies"
                    :search="search"
                    :filter="filter"
                    selectable
                    selection-type="leaf"
                    dense
                    hoverable
                    open-on-click
                    selected-color="blue"
                    @input="propertiesInput()"
                  >
                    <template v-slot:append="{ item }">
                      <v-icon v-if="item.children">mdi-briefcase</v-icon>
                      <v-icon v-else>mdi-office-building</v-icon>
                    </template>
                  </v-treeview>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
  import {mapState} from "vuex";
  import {
    CREATE_USERS_RESET,
    CREATE_USERS
  } from "@/store/users/actions";

  import form from "@/pages/users/mixins/form";

  export default {
    name: "CreationForm",
    mixins: [
      form
    ],
    methods: {
      resetAction() {
        return CREATE_USERS_RESET;
      },
      submitAction() {
        return CREATE_USERS;
      },
      submitActionOptions() {
        return this.form;
      },
    },
    computed: {
      ...mapState({
        loading: state => state.users.creationRequest.loading,
        validation: state => state.users.creationRequest.validation,
      })
    }
  };
</script>

<style scoped>
</style>

